import "nprogress/nprogress.css";
import '../styles/global.scss';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ToastProvider } from 'react-toast-notifications';
import { useRouter } from "next/router";
import { useEffect } from "react";
import TagManager from 'react-gtm-module';

//ngprogress
Router.events.on('routeChangeStart', () => NProgress.start()); Router.events.on('routeChangeComplete', () => NProgress.done()); Router.events.on('routeChangeError', () => NProgress.done());

export default function MyApp({ Component, pageProps }) {
    const router = useRouter();
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-M62N93S' });
    }, [])

    return(
    <ToastProvider>
        <Component {...pageProps} />
    </ToastProvider>
    )
}
