// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = 'https://bca83d8e00be4609815a76bc13625788@o58809.ingest.sentry.io/5983906'

Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env["ENVIROMENT"],
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
})